import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled from 'styled-components';
import { hideError, setActiveConsult, setAppState } from './actions/index';
import './App.css';
import ErrorComponent from './components/common/ErrorComponent';
import { connect } from 'react-redux';
import { Spinner } from './containers/CommonDigitisation/styledCommonComponents';
import { FloatButton } from 'antd';
import { IoReloadOutline } from 'react-icons/io5';


const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  overflow-y: hidden;
`;

const LazyLoginPage = lazy(() => import('./containers/LoginPage'));
const LazyDashboardHome = lazy(() => import('./containers/DashboardHome'));
const LazyVideoCallContainer = lazy(() => import('./containers/VideoCallContainer'));
const LazyAdmin = lazy(() => import('./components/admin/admin'));
const LazyAdminTable = lazy(() => import('./components/admin/AdminTable'));
const LazyDigitiser = lazy(() => import('./components/admin/Digitiser'));
const LazyQcCases = lazy(() => import('./components/admin/QcCases'));
const LazySuspiciousUsers = lazy(() => import('./components/admin/SuspiciousUsers'));


class App extends Component {

  componentDidMount() {
    this.handleOnline();
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  resetStore = (shouldReload = false) => {
    this.props.dispatch(setActiveConsult({}));
    this.props.dispatch(hideError());
    if (shouldReload) {
      window.location.replace(window.location.href);
    }
  }

  handleOnline = () => {
    this.resetStore();
    this.props.dispatch(setAppState('ONLINE'));
  };

  handleOffline = () => {
    this.props.dispatch(setAppState('OFFLINE'));
  };

  render() {
    return (
      <OuterContainer>
        <Router>
          <Suspense fallback={<div style = {{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner primary />
          </div>}>
            <Route path="/" exact component={props => <LazyLoginPage {...props} />} />
            <Route path="/home" component={props => <LazyDashboardHome {...props} />} />
            <Route path="/video-call" component={props => <LazyVideoCallContainer {...props} />} />
            <Route path ="/admin" component={props => <LazyAdmin {...props} />} />
            <Route path ="/admin-table" component={props => <LazyAdminTable {...props} />} />
            <Route path ="/transcriber" component={props => <LazyDigitiser {...props} />} />
            <Route path ="/qc-cases" component={props => <LazyQcCases {...props} />} />
            <Route path ="/suspicious-users" component={props => <LazySuspiciousUsers {...props} />} />
          </Suspense>
        </Router>
        <FloatButton style={{ right: 2.5, bottom: 2.5, height: '25px', width: '25px' }} onClick = {() => this.resetStore(true)} type='primary' icon = {<IoReloadOutline size={15} />} />
        <ErrorComponent />
        
      </OuterContainer>
    );
  }
}

export default connect()(App);
